import type {
    CreateProductData,
    EditProductData,
} from "~/models/ProductCrudData"

export function useCreateProductData() {
    return useState<CreateProductData | null>("createProductData", () => null)
}

export function useEditProductData() {
    return useState<EditProductData | null>("editProductData", () => null)
}
